import store from '../store'
import {getToken} from "@/utils/auth";
import {ElMessage} from "element-plus";
import _ from 'lodash'

export default (router) => {
    router.beforeEach(async (to, from, next) => {
        const isGetInfo = store.state.user.isGetInfo;
        if (!isGetInfo) {
            // 取一次userInfo
            await store.dispatch('user/getInfo')
        }
        next();
    })

    router.beforeEach(async (to, from, next) => {
        // const token = getToken();
        // const isAuthenticated = !!token;
        // if (isAuthenticated && _.isEmpty(store.state.user.name)) {
        //     await store.dispatch('user/getInfo')
        // }
        const isAuthenticated = !!store.state.user.userId;
        if (to.meta.auth && !isAuthenticated) {
            // 如果路由需要认证，但用户未登录，则重定向到登录页面
            ElMessage.error('您还没有登录')
            next({name: 'login'});
        } else {
            // 否则，允许路由继续
            next();
        }
    })
}
