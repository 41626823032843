import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/sso/userSso/login',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/sso/userSso/getInfo',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/sso/userSso/logout',
    method: 'post'
  })
}

export function modifyPwd(data) {
  return request({
    url: '/sso/userSso/modifyPwd',
    method: 'post',
    data
  })
}
